import React from 'react'
import {
    Stack
} from '@mui/material'
import Title from './Title'
import Paragraph from './Paragraph'
import GetInTouch from './GetInTouch'

const Details = () => {

    // const handleSubmit = (event) => {
    //     event.preventDefault();
    //     const data = new FormData(event.currentTarget);
    //     console.log({
    //         email: data.get('email'),
    //         phone: data.get('phone'),
    //     });
    // }


    return (
        <Stack
            component='section'
            direction="column"
            justifyContent='center'
            alignItems='center'
            sx={{
                py: 10,
                px: 2,
            }}
        >
            <Title
                text={
                    'Start learning now'
                }
                textAlign={'center'}
            />
            <Paragraph
                text={
                    'We invite you to download our suite of applications and embark on a fun-filled educational journey with your children or students. Let’s make learning an exciting adventure! Together, we can ignite the spark of learning and help our children reach their full potential.'
                }
                maxWidth={'sm'}
                mx={0}
                textAlign={'center'}
            />
            <Paragraph
                text={
                    'We are committed to providing quality pre-school education to all, structured to meet the modern educational needs of each child. We believe in nurturing curiosity, fostering creativity, and inspiring a lifelong love for learning. Our suite of applications is more than just a tool; it’s a gateway to a world of knowledge, accessible to every child, everywhere. Together, we can light the torch of education, illuminating the path to a brighter future for our children. Because every child deserves the best start in life, and we are here to ensure they get it. 🌟'
                }
                maxWidth={'sm'}
                mx={0}
                textAlign={'center'}
            />
            {/* <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{
                    mt: 1,
                    py: 2
                }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="phone"
                    label="Phone Number"
                    type="phone"
                    id="phone"
                    autoComplete="current-phone"
                />
                <Button
                    variant="contained"
                    fullWidth
                    type="submit"
                    size="medium"
                    sx={{
                        fontSize: '0.9rem',
                        textTransform: 'capitalize',
                        py: 2,
                        mt: 3,
                        mb: 2,
                        borderRadius: 0,
                        backgroundColor: '#14192d',
                        "&:hover": {
                            backgroundColor: '#1e2a5a',
                        }
                    }}
                >
                    send
                </Button>
            </Box> */}
            <Paragraph
                text={
                    'To create your own mobile game/app. +91 892 829 8620'
                }
                maxWidth={'sm'}
                mx={0}
                textAlign={'center'}
            />
          
          <Paragraph
                text={
                    'To license Baby games/app '
                }
                maxWidth={'sm'}
                mx={0}
                textAlign={'center'}
            />
           <Paragraph
                text={
                    '+91 892 829 8620'
                }
                maxWidth={'sm'}
                mx={0}
                textAlign={'center'}
            />
            <Paragraph
                text={
                    'akhilesh.pips@gmail.com'
                }
                maxWidth={'sm'}
                mx={0}
                textAlign={'center'}
            />
            <GetInTouch />
            {/* <Title
                text={
                    '+91 892 829 8620'
                }
                textAlign={'center'}
            /> */}
            {/* <Title
                text={
                    'akhilesh.pips@gmail.com'
                }
                textAlign={'center'}
            /> */}
            {/* <Title
                text={
                    'info@bcodec.com'
                }
                textAlign={'center'}
            /> */}
        </Stack>
    )
}

export default Details;