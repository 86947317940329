import React, { useState } from 'react';
import { Box, Grid, Card, CardContent, Typography, CardActions, Button, CardMedia, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import QRCode from 'react-qr-code';
import imgDetail from '../assets/pexels-alex-staudinger-1732414.jpg';
import imgDetail2 from '../assets/pexels-pixabay-271816.jpg';


const cards = [
  { title: 'Fun Learn', link: '0', desc: 'Easy games for your kid to learn ABCs, 123s, colors, and shapes.', img: imgDetail },
  { title: 'Happy Puzzles', link: '1', desc: 'Puzzles that make your kid think and learn. Simple and fun!', img: imgDetail2 },
  { title: 'Story Time', link: '2', desc: 'Every Night is Story Night!', img: 'https://images.rawpixel.com/image_800/cHJpdmF0ZS9sci9pbWFnZXMvd2Vic2l0ZS8yMDIzLTA0L2pvYjk2OS0wNTUuanBn.jpg' },
  { title: 'Art Star', link: '3', desc: 'Let your kid draw and color. Boost creativity and have fun.', img: 'https://live.staticflickr.com/3375/3291094752_d3b8087aed_b.jpg' },

  // ...other cards...
];

const ResponsiveGrid = () => {
  const [open, setOpen] = useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  const handleDownload = () => {
    window.open('https://drive.google.com/file/d/16lJErEHg7qswCtmzviDKT0fO6oclL9aB/view?usp=drive_link', '_blank');
};

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">

      <Grid container spacing={2} >
        {cards.map((card, index) => (
          <Grid item xs={12} lg={3} key={index}>
            <Card>
              <CardMedia
                component="img"
                height="140"
                image={card.img}
                alt={card.title}
              />
              <CardContent>
                <Typography variant="h5">{card.title}</Typography>
                <Typography variant="body2">{card.desc}</Typography>
              </CardContent>
              <CardActions>
                {card.link === '0' ? <Button size="small" onClick={handleDownload}>Download</Button> : <Button size="small" >Coming soon</Button>}
              </CardActions>
            </Card>
          </Grid>
        ))}
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Learn More</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <QRCode value="http://example.com" />
                <Typography variant="h6">Some Heading</Typography>
                <Typography variant="body1">Some text...</Typography>

              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">Some Heading</Typography>
                <Typography variant="body1">Some text...</Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" href="#">Download</Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Box>
  );
};

export default ResponsiveGrid;
