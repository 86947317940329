import React from 'react'
import { Box, Button, styled, Typography } from "@mui/material";
//import { Link } from 'react-router-dom'
//img
import headerImg from '../assets/pexels-binyamin-mellish-186078.png'
import TextTransition, { presets } from 'react-text-transition';
import Ticker, {  NewsTicker } from 'nice-react-ticker';

const TEXTS = [
    'क्या आपका बच्चा 2-6 साल का है?',  // Hindi
    'Is your child 2 to 6 years old?',
    'আপনার সন্তানের বয়স কি 2 থেকে 6 বছর?',  // Bengali
    'మీరు 2 నుండి 6 సంవత్సరాల వయస్సు గల పిల్లవా ?',  // Telugu
    'तुमचे मूल 2 ते 6 वर्षांचे आहे?',  // Marathi
    'உங்கள் குழந்தைக்கு 2 முதல் 6 வயது ?',  // Tamil
    'શું તમારું બાળક 2 થી 6 વર્ષનું છે ?',  // Gujarati
    'ನಿಮ್ಮ ಮಗುವಿಗೆ 2 ರಿಂದ 6 ವರ್ಷ ?'  // Kannada
];
const subtitles = [
    'डिजिटल इंडिया के छोटे विद्वान',  // Hindi,
    'Digital India’s Tiny Scholars',
    'ডিজিটাল ইন্ডিয়ার ছোট পন্ডিত',  // Bengali
    'డిజిటల్ ఇండియా యొక్క చిన్న విద్వాంసులు',  // Telugu
    'डिजिटल इंडियाचे लहान विद्वान',  // Marathi
    'டிஜிட்டல் இந்தியாவின் சிறு அறிஞர்கள்',  // Tamil
    'ડિજિટલ ઇન્ડિયાના નાના વિદ્વાનો',  // Gujarati
    'ಡಿಜಿಟಲ್ ಇಂಡಿಯಾದ ಚಿಕ್ಕ ವಿದ್ವಾಂಸರು'  // Kannada
];

const Header = () => {
    const [index, setIndex] = React.useState(0);
    const handleClick = () => {
        const nextSection = document.getElementById('next-section');
        if (nextSection) {
            nextSection.scrollIntoView({ behavior: 'smooth' });
        }
    };
    // const handleDownload = () => {
    //     window.open('https://drive.google.com/file/d/1rS1huP_Hg2wrA4zNnL0rXZVbOAGitG2a/view?usp=drive_link', '_blank');
    // };
    React.useEffect(() => {
        const intervalId = setInterval(
            () => setIndex((index) => index + 1),
            3000, // every 3 seconds
        );
        return () => clearTimeout(intervalId);
    }, []);
    const CustomBox = styled(Box)(({ theme }) => ({
        minHeight: '80vh',
        display: 'flex',
        justifyContent: 'center',
        // tamanhos
        gap: theme.spacing(2),
        paddingTop: theme.spacing(10),
        // cor de fundo
        backgroundColor: 'orange',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
        }
    }));

    const BoxText = styled(Box)(({ theme }) => ({
        flex: '1',
        paddingLeft: theme.spacing(8),
        [theme.breakpoints.down('md')]: {
            flex: '2',
            textAlign: 'center',
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    }));


    return (
        <>
            <Ticker isNewsTicker={false}>
                <NewsTicker id="1" title="Download Fun Learn" />
            </Ticker>
            <CustomBox component='header'>
                {/*  Box text  */}

                <BoxText
                    component='section'
                >
                    <Typography
                        variant='h2'
                        component='h1'
                        sx={{
                            fontWeight: 700,
                            color: '#fff',
                        }}
                    >
                        <TextTransition springConfig={presets.wobbly}>{TEXTS[index % TEXTS.length]}</TextTransition>
                    </Typography>

                    <Typography
                        variant='p'
                        component='p'
                        sx={{
                            py: 3,
                            lineHeight: 1.6,
                            color: '#fff',
                        }}
                    >
                        <TextTransition springConfig={presets.wobbly}>{subtitles[index % subtitles.length]}</TextTransition>

                    </Typography>

                    <Box>
                        <Button variant="contained" color="primary" onClick={handleClick}>
                            Yes
                        </Button>

                        {/* <Button 
                    component={Link} 
                    to={'/about'}
                    variant='outlined'
                    sx={{
                        px: 4, 
                        py: 1,
                        fontSize:'0.9rem',
                        textTransform: 'capitalize',
                        borderRadius: 0,
                        color: '#fff',
                        backgroundColor: 'transparent',
                        borderColor: '#fff',
                        "&&:hover": {
                            color: '#343a55',
                            borderColor: '#343a55',
                        },
                        "&&:focus": {
                            color: '#343a55',
                            borderColor: '#343a55',
                        }
                    }}
                    >
                        explore
                    </Button> */}
                    </Box>
                </BoxText>

                <Box sx={theme => ({
                    [theme.breakpoints.down('md')]: {
                        flex: '1',
                        paddingTop: '30px',
                        alignSelf: 'center',
                    },
                    [theme.breakpoints.up('md')]: {
                        flex: '2',
                        alignSelf: 'flex-end',
                    },
                })}
                >
                    <img
                        src={headerImg}
                        alt="headerImg"
                        style={{
                            width: "100%",
                            marginBottom: -15,
                        }}
                    />
                </Box>

            </CustomBox>



        </>
    )
}

export default Header