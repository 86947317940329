import React from 'react'
//import GetInTouch from '../components/GetInTouch';
//import GetStarted from '../components/GetStarted';
import Header from '../components/Header';
import Section3 from '../components/section3';
import ResponsiveGrid from '../components/ResponsiveGrid';

const Home = () => {
  return (
    <>
    <Header />
    <ResponsiveGrid />
    {/* <GetStarted /> */}
    <Section3/>
    {/* <GetInTouch /> */}
    </>

  )
}

export default Home