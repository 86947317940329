import { Link,  List,
  ListItem, } from '@mui/material'
import React from 'react'

const FooterLink = ({ text }) => {
  return (
    <List >
    {/* // href="/about"
    // variant='p'
    // component='a' */}
    <ListItem 

    component={Link} 
    to={"/about"}

    sx={{
      fontSize: '0.9rem',
      fontWeight: '400',
      textDecoration: 'none',
      color: '#414141',
      textTransform: 'capitalize',
      "&:hover": {
        color: '#1c2859',
      }
    }}
    >
      {text}
      </ListItem>

    </List>
  )
}

export default FooterLink