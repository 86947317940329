import React from 'react'
import {
    Button,
    Stack,
} from '@mui/material'
import Title from './Title'
import Paragraph from './Paragraph'
//import { Link } from 'react-router-dom'

const GetInTouch = () => {
    const handleDownload = () => {
        window.open('https://drive.google.com/file/d/1rS1huP_Hg2wrA4zNnL0rXZVbOAGitG2a/view?usp=drive_link', '_blank');
    };
    return (
        <Stack
            component='section'
            direction="column"
            justifyContent='center'
            alignItems='center'
            sx={{
                py: 10,
                mx: 6,
            }}
        >
            {/* <Title
                text={
                    'Start learning now'
                }
                textAlign={'center'}
            />
            <Paragraph
                text={
                    'We invite you to download our suite of applications and embark on a fun-filled educational journey with your children or students. Let’s make learning an exciting adventure! Together, we can ignite the spark of learning and help our children reach their full potential.'
                }
                maxWidth={'sm'}
                mx={0}
                textAlign={'center'}
            />
            <Paragraph
                text={
                    'We are committed to providing quality pre-school education to all, structured to meet the modern educational needs of each child. We believe in nurturing curiosity, fostering creativity, and inspiring a lifelong love for learning. Our suite of applications is more than just a tool; it’s a gateway to a world of knowledge, accessible to every child, everywhere. Together, we can light the torch of education, illuminating the path to a brighter future for our children. Because every child deserves the best start in life, and we are here to ensure they get it. 🌟'
                }
                maxWidth={'sm'}
                mx={0}
                textAlign={'center'}
            /> */}
           
          <h4>About me</h4>
              <Title
                text={
                    'Akhilesh Singh Pipaleshwar'
                }
                textAlign={'center'}
            />
            <Paragraph
                text={
                    `Hello there! I’m a proud alumnus of the prestigious National Institute of Technology, Durgapur, where I honed my skills from 2010 to 2014. But don’t let that fool you into thinking I’m just another tech graduate. You see, I’ve always had a soft spot for teaching. For over three years, I’ve been associated with leading teaching institutes in Mumbai, such as IMS Learning Resources and Avanti Learning Centre, serving as a Chemistry faculty.

                    I’ve always believed that education is the greatest equalizer, and it’s been my passion to bring it to underprivileged kids. But back in 2014, I noticed a glaring problem - the lack of proper online learning resources. So, what did I do? I rolled up my sleeves, dived headfirst into the world of coding, and emerged as a seasoned React Native developer.
                    
                  `
                }
                maxWidth={'sm'}
                mx={0}
                textAlign={'center'}
            />
            <Paragraph
                text={
`  Over the past six years, I’ve worked with top companies like Reliance Jio and Larsen & Toubro, developing full-stack React Native mobile applications. But here’s the twist - I’ve never quite fit the mold of corporate creativity. Instead, I channel my creativity into creating beautiful, user-friendly games.
                    
So, if you’re looking for a tech-savvy, teaching-enthusiast with a knack for breaking the mold, you’re in the right place. And remember, in the world of coding and chemistry, I’m your friendly neighborhood Spiderman, always ready to swing into action!`                }
                maxWidth={'sm'}
                mx={0}
                textAlign={'center'}
            />
             <Button variant="contained" color="primary" onClick={handleDownload}>
             Download Fun Learn
                    </Button>
        </Stack>
    )
}

export default GetInTouch;